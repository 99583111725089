import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
import logo from "../../../Assets/Images/logo.svg";
import { toast } from "react-toastify";
import AxiosInstance from '../../../Config/AxiosInstance';
import { ApiList } from '../../../Config/ApiList'
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Email address is required!")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Please Provide Valid Email"
        ),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true);
        const params = {
          email: values.email,
        };
        const res = await AxiosInstance.post(ApiList.FORGOT_PASSWORD, params);
        if (res && res?.data?.s === 1) {
          toast.success(res?.data?.m);
          navigate('/login');
        } else {
          toast.error(res?.data?.m)
        }
      } catch (error) {
        console.log(error);
        toast.error(error);
      } finally {
        setIsLoading(false);
        resetForm();
      }
    },
  });
  return (
    <>
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 2,
        }}
      >
        <Box
          sx={{
            width: { xs: "95%", sm: "60%", md: "50%", lg: "40%", xl: "30%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <img
                src={logo}
                alt="logo"
                style={{ width: "100%", height: "100%" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              background: "#F0F0F0",
              borderRadius: "60px",
              py: 1,
              position: "relative",
              zIndex: "2",
            }}
          >
            <Box sx={{ height: "100%", width: "100%", p: 3 }}>
              <Typography sx={{ fontSize: "25px", fontWeight: "500" }}>
                Forget Password?
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "#000000B2" }}>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
              </Typography>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                  return false;
                }}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "1.5rem",
                  paddingTop: "30px",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <CustomTextField
                    label="Email Address"
                    type="text"
                    placeholder="Email Address"
                    name="email"
                    value={formik.values.email}
                    onBlur={formik.handleBlur}
                    onKeyUp={formik.handleBlur}
                    errors={formik.touched.email && formik.errors.email}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.trimStart() !== value) {
                        e.target.value = value.trimStart();
                      }
                      formik.handleChange(e);
                    }}
                  />
                </Box>
                <Box sx={{ width: "100%", display: "flex", gap: "20px" }}>
                  <CustomLoaderButton
                    btnTitle="Back"
                    type="button"
                    onSubmit={() => navigate('/login')}
                  />
                  <CustomLoaderButton
                    btnTitle="Forgot Passwprd"
                    loading={isLoading}
                    type="submit"
                  />
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
