import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomFileUploadMultiple from "../../../Components/CustomFileUpload/CustomFileUploadMultiple";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { toast } from "react-toastify";
import moment from "moment/moment";
import Skeleton from "react-loading-skeleton";
import CustomLoaderButton from '../../../Components/CustomLoaderButton/CustomLoaderButton';
import CustomDatePicker from '../../../Components/CustomDatePicker/CustomDatePicker';
const AddGallery = (props) => {
  const { handleSubmit, open, onClose, close } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPhotoPrev, setSelectedPhotoPrev] = useState([]);
  const [dropDownList, setDropDownList] = useState([]);
  const handleOnChangeGallery = (acceptedFiles) => {
    const validFileTypes = ["image/jpeg", "image/png", "image/webp", "image/jpg"];
    const maxSize = 2 * 1024 * 1024;
    const invalidTypeFiles = [];
    const oversizedFiles = [];
    const validFiles = acceptedFiles.filter((file) => {
      if (!validFileTypes.includes(file.type)) {
        invalidTypeFiles.push(file);
        return false;
      }
      if (file.size > maxSize) {
        oversizedFiles.push(file);
        return false;
      }
      return true;
    });
    if (invalidTypeFiles.length > 0) {
      toast.error(`Some files have invalid file types`);
    }
  
    if (oversizedFiles.length > 0) {
      toast.error(`Some files exceed the size limit of 2MB`);
    }
    const newImages = validFiles.map((file) => ({
      id: uuidv4(),
      image: URL.createObjectURL(file),
    }));
    setSelectedPhotoPrev((prev) => [...prev, ...newImages]);
    formik.setFieldValue("thumb", [
      ...formik.values.thumb,
      ...validFiles,
    ]);
  };

  const handleRemoveGalleryImage = (data) => {
    const newSelectedPartnerImages = selectedPhotoPrev.filter(
      (img) => img.id !== data.id
    );
    const newSelectedPartnerImageFile = formik.values.thumb.filter(
      (_, i) => selectedPhotoPrev[i].id !== data.id
    );

    setSelectedPhotoPrev(newSelectedPartnerImages);
    formik.setFieldValue("thumb", newSelectedPartnerImageFile);
  };

  const getCategoryList = async () => {
    try {
      const response = await AxiosInstance.get(ApiList.GET_CATEGORY, {
        params: { type: 1 },
      });
      if (response && response?.data?.r) {
        setDropDownList(response?.data?.r);
      } else {
        setDropDownList([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategoryList();
  }, []);

  const formik = useFormik({
    initialValues: {
      thumb: [],
      scheduleSelected: "1",
      selectCategory: 0,
      timeSelected: null
    },
    validationSchema: Yup.object().shape({
      thumb: Yup.array()
        .min(1, "At least one image is required")
        .required("This field is required"),
      selectCategory: Yup.string()
        .test("is-not-zero", "Category name is required!", (value) => value !== "0")
        .required("Category is required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("cat_id", values.selectCategory);
        values.thumb.forEach((element) => {
          formData.append("img", element);
        });
        if (values.scheduleSelected === "2") {
          formData.append('schedule_date', moment(values.timeSelected?.$d).utc().format('YYYY-MM-DD HH:mm:ss'));
        }
        const res = await AxiosInstance.post(ApiList.POST_GALLERY, formData);
        console.log("res", res);
        if (res && res?.data?.s === 1) {
          toast.success('Gallery Add Successfully');
        }
      } catch (error) {
        console.log('error', error);
      } finally {
        resetForm();
        setSelectedPhotoPrev([]);
        setDropDownList([]);
        handleSubmit();
        getCategoryList();
        setIsLoading(false);
      }
    },
  });
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "40px",
            height: "650px",
            overflow: "scroll",
            padding: '50px 50px 0px 50px',
            "::-webkit-scrollbar" : {width:"0px",height:'0px'}
          },
          "& .MuiDialogContent-root": {
            overflowY: "initial",
          },
        }}
      >
        <Box
          sx={{ width: "100%", justifyContent: "center", display: "flex", justifyContent: 'space-between', marginBottom: '30px' }}
        >
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ fontSize: "32px", fontWeight: "500" }}>
            Add Details
          </Typography>
          <Typography></Typography>
        </Box>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomFileUploadMultiple
                accept="image/*"
                onDrop={(acceptedFiles, rejectedFiles) => handleOnChangeGallery(acceptedFiles, rejectedFiles)}
                selectedFile={selectedPhotoPrev}
                onRemove={(file) => handleRemoveGalleryImage(file)}
                // isLoading={isLoading}
              />
              {formik.touched.thumb && formik.errors.thumb ? (
                <Typography
                  sx={{
                    color: "red",
                    fontWeight: "400",
                    fontSize: { xs: "14px", sm: "14px" },
                    marginTop: "5px",
                  }}
                >
                  {formik.errors.thumb}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <CustomDropDown
                placeholder={"Select Category"}
                menuList={dropDownList}
                Title={"Select Category"}
                NoData={"No Category Available"}
                value={formik.values.selectCategory}
                onChange={(e) => {
                  formik.setFieldValue("selectCategory", e.target.value);
                }}
                onBlur={formik.handleBlur}
                errors={
                  formik.touched.selectCategory &&
                  formik.errors.selectCategory
                }
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%'
                  }}>
                    <RadioGroup
                      value={formik.values.scheduleSelected}
                      name="scheduleSelected"
                      onChange={formik.handleChange}
                      sx={{
                        width: '100%'
                      }}
                    >
                      {isLoading ? <Skeleton height={'60px'} /> : <Box sx={{ display: "flex", width: "100%", alignItems: 'center', justifyContent: 'center' }}>
                        <FormControlLabel
                          value={"1"}
                          control={<Radio />}
                          label="Publish Now"
                        />
                        <FormControlLabel
                          value={"2"}
                          control={<Radio />}
                          label="Schedule Later"
                        />
                      </Box>}
                    </RadioGroup>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {formik.values.scheduleSelected === "2" && <CustomDatePicker
                value={formik.values.timeSelected}
                onChange={(newValue) => formik.setFieldValue('timeSelected', newValue)}
                error={formik.touched.timeSelected && formik.errors.timeSelected}
              />
              }
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ py: 3, px: 4, display: 'flex', width: "100%", justifyContent: "center" }}>
                <CustomLoaderButton btnTitle={"Add"} sx={{ width: "150px" }} type='submit' loading={isLoading} />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </>
  );
};

export default AddGallery;
