import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";

const PiChartTwo = () => {
  const [dashboardData, setDashboardData] = useState({ total_users: 0, new_users: 0, today_active_users: 0 });

  const getDashboaredData = async () => {
    try {
      const response = await AxiosInstance.get(ApiList.DASHBOARD);
      if (response && response?.data?.s === 1) {
        setDashboardData(response?.data?.r);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    getDashboaredData();
  }, []);

  const data = {
    labels: [
      `Total Users ${dashboardData?.total_users ? dashboardData?.total_users : 0}`,
      `New Users ${dashboardData?.new_users ? dashboardData?.new_users : 0}`,
      `Active Users ${dashboardData?.today_active_users ? dashboardData?.today_active_users : 0}`,
    ],
    datasets: [
      {
        label: "User Statistics",
        data: [
          dashboardData?.total_users,
          dashboardData?.new_users,
          dashboardData?.today_active_users,
        ],
        backgroundColor: ["#D3D3D3", "#6A5ACD", "#00008B"],
        hoverOffset: 4, 
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          font: {
            size: 18,
          },
        },
      },
      title: {
        display: true,
        text: "Total Mobile Users",
        align: "start",
        color: "black",
        font: {
          size: 26,
          weight: 600,
        },
        padding: {
          top: 10,
          bottom: 30,
          start: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || "";
            if (label) {
              label += ": ";
            }
            if (context.raw !== null) {
              label += new Intl.NumberFormat().format(context.raw);
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%', display: "flex", justifyContent: 'center' }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default PiChartTwo;
