import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import AddLiveStream from "./AddLiveStream";
import PiChartOne from "./PiChartOne";
import PiChartTwo from "./PiChartTwo";

const Dashboard = () => {
  const [openVideoModal, setOpenVideoModal] = useState(false);

  return (
    <>
      <Box sx={{ width: "100%", padding: '15px' }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: '24px'
          }}
        >
          <Typography
            sx={{ fontSize: { xs: "22px", md: "32px" }, fontWeight: "500" }}
          >
            Healing Institute Dashboard
          </Typography>
          <Button
            sx={{
              background: "#000E72",
              color: "white",
              "&:hover": { background: "#000E72", color: "white" },
              textTransform: "none",
            }}
            onClick={() => setOpenVideoModal(true)}
          >
            + Add Stream
          </Button>
        </Box>
        <Box sx={{
          borderTop: "1px solid #0000003D",
          padding: '24px 0px 24px 0px'
        }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} sx={{display:'flex',justifyContent:'center'}}>
              <Box sx={{
                width:"100%",
                boxShadow: '0px 4px 18px 0px #00000014',
                backgroundColor: "#fff",
                padding: '32px',
                borderRadius: '16px'
              }}>
                <PiChartTwo />
              </Box>

            </Grid>
            <Grid item xs={12} md={6} sx={{display:'flex',justifyContent:'center'}}>
              <Box sx={{
                width: "100%",
                boxShadow: '0px 4px 18px 0px #00000014',
                backgroundColor: "#fff",
                padding: '32px',
                borderRadius: '16px'
              }}>
                <PiChartOne />
              </Box>
            </Grid>
          </Grid>
        </Box>

      </Box>

      <AddLiveStream
        open={openVideoModal}
        onClose={() => setOpenVideoModal(false)}
        close={() => setOpenVideoModal(false)}
      />
    </>
  );
};

export default Dashboard;
