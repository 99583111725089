import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import { toast } from "react-toastify";
import deleteIcon from "../../../Assets/Images/delete.png";
import EditIcon from "../../../Assets/Images/edit.png";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import Swal from "sweetalert2";

const FAQs = () => {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [queAns, setQueAns] = useState([]);
  const [typeCheck, setTypeCheck] = useState(null);
  const [editFaqId, setEditFaqId] = useState(null);

  const getQuestionAnswers = async (page) => {
    setIsLoading(true);
    try {
      const params = {
        count: page * pageSize,
        is_admin: 1,
      };
      const res = await AxiosInstance.get(ApiList.GET_FAQ, { params });
      if (res && res?.data?.s === 1) {
        const Rowdata = res?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setQueAns(Rowdata);
        setCount(res?.data?.c);
      } else {
        setQueAns([]);
        setCount(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQuestionAnswers(page);
  }, [page]);

  const handleEditVideo = (data) => {
    if (data?.id) {
      const editData = queAns?.find((ele) => ele?.id === data?.id);
      if (editData) {
        setTypeCheck("Edit");
        setEditFaqId(data?.id);
        formik.setValues({
          question: editData?.question || "",
          answer: editData?.answer || "",
        });
      }
    }
  };

  const handleDeleteVideo = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this affirmation",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          id: id,
          status: "0",
        };
        try {
          const response = await AxiosInstance.post(ApiList.EDIT_FAQ, params);
          if (response?.data?.s === 1) {
            getQuestionAnswers(page);
            toast.success("Deleted Successfully!");
          }
        } catch (error) {
          toast.error("Failed to delete.");
        }
      }
    });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const columns = [
    { field: "rowid", headerName: "Sr.No.", width: 100 },
    {
      field: "question",
      headerName: "Questions",
      flex: 1,
    },
    {
      field: "answer",
      headerName: "Answers",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <img
            src={EditIcon}
            alt="editIcon"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => handleEditVideo(params?.row)}
          />
          <img
            src={deleteIcon}
            alt="delete"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => handleDeleteVideo(params?.row?.id)}
          />
        </Box>
      ),
    },
  ];

  const formik = useFormik({
    initialValues: {
      question: "",
      answer: "",
    },
    validationSchema: Yup.object().shape({
      question: Yup.string().required("Please enter question here"),
      answer: Yup.string().required("Please enter answer here"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("question", values.question);
        formData.append("answer", values.answer);
        if (typeCheck === "Edit") {
          formData.append("id", editFaqId);
          const response = await AxiosInstance.post(ApiList.EDIT_FAQ, formData);
          if (response?.data?.s === 1) {
            toast.success(response?.data?.m);
          }
        } else {
          const response = await AxiosInstance.post(ApiList.ADD_FAQ, formData);
          if (response?.data?.s === 1) {
            toast.success(response?.data?.m);
          }
        }
        resetForm();
        getQuestionAnswers(page);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
  });
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
            <CustomTextField
              label="Add Question"
              type="text"
              placeholder="Question"
              name="question"
              value={formik.values.question}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errors={formik.touched.question && formik.errors.question}
              onKeyUp={formik.handleChange}
              sx={{
                "& .MuiInputBase-root": {
                  background: "#F0F0F0",
                  borderRadius: "10px",
                },
              }}
            />
            <CustomTextField
              label="Add Answer"
              type="text"
              placeholder="Answer"
              name="answer"
              value={formik.values.answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errors={formik.touched.answer && formik.errors.answer}
              onKeyUp={formik.handleChange}
              multiline
              rows={5}
              sx={{
                "& .MuiInputBase-root": {
                  background: "#F0F0F0",
                  borderRadius: "10px",
                },
              }}
            />
            <Box
              sx={{
                py: 3,
                px: 0,
                display: "flex",
                width: "100%",
                justifyContent: "end",
              }}
            >
              <CustomLoaderButton
                btnTitle={"Add"}
                sx={{ width: "150px" }}
                type={"submit"}
                loading={isLoading}
              />
            </Box>
          </Box>
        </form>
      </Box>
      <Box sx={{ padding: "24px 0px 24px 0px" }}>  
        <TableContainer
          rows={queAns}
          loading={isLoading}
          columns={columns}
          pageSize={pageSize}
          page={page}
          totalCount={count}
          handlePageChange={handlePageChange}
          sx={{ height: "auto" }}
        />
      </Box>
    </>
  );
};

export default FAQs;
