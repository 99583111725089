import React from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography, IconButton } from "@mui/material";
import cloudIcon from "../../Assets/Images/uploadIcon.png";
import CloseIcon from "@mui/icons-material/Close";

const CustomFileUploadMultiple = ({ onDrop, accept, selectedFile, onRemove, isLoading }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          p: selectedFile ? "0px" : 4,
          borderRadius: 4,
          backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
          border: "1px solid black",
        }}
      >
        <div
          {...getRootProps()}
          style={{
            cursor: "pointer",
            padding: "20px",
            borderRadius: "8px",
            backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={cloudIcon} alt="" />
            <Typography
              variant="body1"
              color="black"
              sx={{
                mt: 1,
                textAlign: "center",
                px: { xs: 2, sm: 3, md: 3, lg: 10, xl: 10 },
                fontWeight: "500",
              }}
            >
              <span style={{ color: "#000E72" }}>Click here</span> to upload
              image or drag and drop
            </Typography>
            <Typography
              variant="body1"
              color="black"
              sx={{
                mt: 1,
                textAlign: "center",
                px: { xs: 2, sm: 3, md: 3, lg: 10, xl: 10 },
                fontWeight: "500",
              }}
            >
              <span style={{ color: "#ddd" }}>(JPG,JPEG,PNG,WEBP)</span>
            </Typography>
          </Box>
        </div>
      </Box>
      {selectedFile && selectedFile?.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
            {selectedFile.map((src, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRadius: "8px",
                  padding: "10px",
                  border: "1px solid #ddd",
                }}
              >
                <img
                  src={src?.image}
                  alt={`Preview ${index}`}
                  style={{ width: 100, height: 100, objectFit: "cover" }}
                />
                <IconButton
                  onClick={() => onRemove(src)}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "white",
                    padding: 0.5,
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default CustomFileUploadMultiple;
