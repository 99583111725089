import { Navigate } from "react-router-dom";
import AdminLayout from "../Layout/AdminLayout";
// ==========Auth Routes==========
import Login from '../Pages/Auth/Login/Login';
import ForgotPassword from '../Pages/Auth/ForgotPassword/ForgotPassword';
// ==========Admin Routes==========
import Dashboard from "../Pages/Admin/Dashboard/Dashboard";
import UserManagement from "../Pages/Admin/UserManagement/UserManagement";
import RegisterManagement from '../Pages/Admin/RegisterManagement/RegisterManagement';
import VideoManagement from "../Pages/Admin/VideoManagement/VideoManagement";
import GalleryManagement from '../Pages/Admin/GalleryManagement/GalleryManagement'
import ResourcesManagement from "../Pages/Admin/ResourcesManagement/ResourcesManagement";
import AddMarchendise from "../Pages/Admin/ResourcesManagement/AddMarchendise";
import AffirmativeTipsManagement from '../Pages/Admin/AffirmativeTipsManagement/AffirmativeTipsManagement';
import DailyHealthTipsManagement from '../Pages/Admin/DailyHealthTipsManagement/DailyHealthTipsManagement';
import ScheduleEventManagement from '../Pages/Admin/ScheduleEventManagement/ScheduleEventManagement';
import ContentManagement from '../Pages/Admin/ContentManagement/ContentManagement';
import AddAffirmativeTip from "../Pages/Admin/AffirmativeTipsManagement/AddAffrimativeTip";
import AddDailyHealthTips from "../Pages/Admin/DailyHealthTipsManagement/AddDailyHealthTips";
import AddScheduleEventManagement from "../Pages/Admin/ScheduleEventManagement/AddScheduleEventManagement";
const routes = [
    {
      path: "/",
      component: <Navigate to="/login" component={<Login />} />,
      exact: true,
    },
    {
      path: "/login",
      component: <Login />,
      exact: true,
    },
    {
      path: "/forgot-password",
      component: <ForgotPassword />,
      exact: true,
    },
    {
      path: "/admin/*",
      component: <AdminLayout />,
      exact: true,
    },
];
export const adminLayoutRoute = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/user-management",
    component: <UserManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/register-management",
    component: <RegisterManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/video-management",
    component: <VideoManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/gallery-management",
    component: <GalleryManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/resouces-management",
    component: <ResourcesManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/resouces-management/add-marchendise",
    component: <AddMarchendise />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/resouces-management/edit-marchendise",
    component: <AddMarchendise />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/affirmative-tips-management",
    component: <AffirmativeTipsManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/daily-health-tips-management",
    component: <DailyHealthTipsManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/add-daily-health-tips-management",
    component: <AddDailyHealthTips />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/edit-daily-health-tips-management",
    component: <AddDailyHealthTips />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/schedule-event-management",
    component: <ScheduleEventManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/content-management",
    component: <ContentManagement />,
    exact: true,
    sidebar: true,
  },
  {
    path: "/add-affirmative-tips",
    component: <AddAffirmativeTip />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/edit-affirmative-tips",
    component: <AddAffirmativeTip />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/add-schedule-event",
    component: <AddScheduleEventManagement />,
    exact: true,
    sidebar: false,
  },
  {
    path: "/edit-schedule-event",
    component: <AddScheduleEventManagement />,
    exact: true,
    sidebar: false,
  },
];
export default routes;