import { Box, MenuItem, Select, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
const CustomDropDown = ({ disabled, value, onChange, NoData, menuList, placeholder, sx = {}, errors, Title, isLoading }) => {
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {Title && <Typography
          sx={{
            color: "black",
            fontWeight: "500",
            textAlign: "left",
            fontSize: { xs: "16px", sm: "18px" },
          }}
        >
          {Title}
        </Typography>}
        {isLoading ? <Skeleton height={'60px'} /> : <Select
          sx={{
            width: "100%",
            height: "54px",
            border: "none",
            background: "#F0F0F0",
            borderRadius: "10px",
            "&:focus": {
              outline: "none",
              boxShadow: "none",
            },
            "&:hover": {
              outline: "none",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            ...sx
          }}
          name="selectCategory"
          value={value}
          onChange={onChange}
          displayEmpty
          disabled={disabled}
        >
          <MenuItem value={0}
            disabled>{placeholder}</MenuItem>
          {menuList ? menuList.map((elem, index) => (
            <MenuItem key={index} value={elem.id}>{elem?.name}</MenuItem>
          )) : <Typography>{NoData}</Typography>}
        </Select>}
        {errors && <Typography
          sx={{
            color: "red",
            fontWeight: "400",
            fontSize: { xs: "14px", sm: "14px" },
            marginTop: "5px",
          }}
        >
          {errors}

        </Typography>}
      </Box>
    </>
  );
};

export default CustomDropDown;
