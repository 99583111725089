import React, { Fragment, useEffect, useState } from 'react';
import { Box, Chip, Grid, IconButton, Typography } from "@mui/material";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import CustomSearch from "../../../Components/CustomSearch/CustomSearch";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import useDebounce from '../../../Components/useDebounce/useDebounce';
import TableContainer from "../../../Components/TableContainer/TableContainer";
import deleteIcon from "../../../Assets/Images/delete.png";
import BlockIcon from '@mui/icons-material/Block';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';

const UserManagement = () => {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchUser, setSearchUser] = useState("");
  const debouncedSearch = useDebounce(searchUser, 500);

  const menuList = [
    { id: null, name: "All" },
    { id: 1, name: "Active" },
    { id: -1, name: "Deleted" },
    { id: -2, name: "Blocked" },
  ];
  const columns = [
    { field: "rowid", headerName: "Sr.No.", width: 100 },
    { field: "user_name", headerName: "User Name", flex: 1 },
    {
      field: "email",
      headerName: "Email",
      minWidth: 300,
      renderCell: (params) => (params?.row?.email ? params?.row?.email : "--")
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        let status = params?.row?.status;
        if (status === 1) {
          return <Chip label="Active" sx={{ color: '#ffffff', backgroundColor: "#5fdba7", cursor: 'pointer' }} />;
        } else if (status === -1) {
          return <Chip label="Deleted" sx={{ color: '#ffffff', backgroundColor: "red", cursor: 'pointer' }} />;
        } else if (status === -2) {
          return <Chip label="Blocked" sx={{ color: '#ffffff', backgroundColor: "#d5d6db", cursor: 'pointer' }} />;
        }
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        let status = params?.row?.status;
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", gap: "2rem" }}>
            <img
              src={deleteIcon}
              alt="delete"
              style={{ opacity: status === 1 || status === -2 ? "1" : "0.5", width: "24px", height: "24px", borderRadius: "5px" }}
              onClick={() => (status === 1 || status === -2) && handleDeleteUser(params.row.id, -1)}
            />
            <IconButton
              onClick={() => {
                if (status === 1 || status === -2) {
                  handleDeleteUser(params.row.id, status === 1 ? -2 : 1);
                }
              }}
            >
              {status === 1 || status === -2 ? (
                status === 1 ? (
                  <BlockIcon sx={{ color: 'red' }} />
                ) : (
                  <BlockIcon sx={{ color: '#000' }} />
                )
              ) : (
                <DoNotDisturbAltIcon sx={{color:''}}/>
              )}
            </IconButton>

            {/* <img
              src={}
              alt="block"
              style={{ width: "24px", height: "24px", borderRadius: "5px", opacity: status === 1 || status === -2 ? "1" : "0.5" }}
              
            /> */}
          </Box>
        );
      },
    },
  ];
  const getUsersList = async () => {
    setIsLoading(true);
    try {
      const params = {
        count: page * pageSize,
        status: selectedStatus,
        search: debouncedSearch,
      };
      const response = await AxiosInstance.get(ApiList.GET_USERS, { params });
      if (response?.data?.s) {
        const Rowdata = response.data.r.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setUserList(Rowdata);
        setIsLoading(false);
        setCount(response?.data?.c)
      } else {
        setUserList([]);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsersList();
  }, [selectedStatus, debouncedSearch, page]);
  const handleDeleteUser = async (id, status) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to ${status === -1 ? "delete" : status === 1 ? "unblock" : status === -2 ? "block" : ""} this user`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: `Yes, ${status === -1 ? "delete" : status === 1 ? "unblock" : status === -2 ? "block" : ""} it`,
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = { id, status };
        try {
          const res = await AxiosInstance.post(ApiList.DELETE_BLOCK_USER, params);
          if (res?.data?.s === 1) {
            toast.success(`${status === -1 ? "Deleted" : status === 1 ? "Unblocked" : "Blocked"} Successfully`);
            getUsersList();
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (e) => {
    setSearchUser(e.target.value);
    setPage(0);
    setCount(0);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setPage(0);
    setCount(0);
  };

  return (
    <Fragment>
      <Box sx={{
        width: '100%',
        padding: '15px'
      }}>
        <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "500", paddingBottom: '10px' }}>
          User Management
        </Typography>
        <Box sx={{ padding: "24px 0px 24px 0px", borderTop: '1px solid #0000003D' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8} lg={8}>
              <CustomSearch
                placeholder="Search User"
                value={searchUser}
                onChange={handleSearchChange}
                onClear={() => handleSearchChange({ target: { value: '' } })}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <CustomDropDown
                menuList={menuList}
                value={selectedStatus}
                onChange={handleStatusChange}
                placeholder={"Select Status"}
              />
            </Grid>
          </Grid>
          <Box sx={{ padding: "24px 0px 24px 0px" }}>
            <TableContainer
              rows={userList}
              columns={columns}
              pageSize={pageSize}
              loading={isLoading}
              page={page}
              handlePageChange={handlePageChange}
              totalCount={count}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default UserManagement;
