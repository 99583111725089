import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';

const CustomDateTimePicker = ({ value, onChange, error, inputFormat }) => {
  const validValue = dayjs.isDayjs(value) ? value : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        value={validValue}
        onChange={onChange}
        // inputFormat="YYYY-MM-DD HH:mm"
        inputFormat={inputFormat}
        ampm={false}
        showTodayButton
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error}
            fullWidth
          />
        )}
        sx={{
          width: '100%',
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDateTimePicker;
