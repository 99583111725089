import React, { useEffect, useState } from "react";
import {
    Box,
    Dialog,
    IconButton,
    Typography,
    Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Constant from "../../../utills/Constant";
const ViewVideoModel = (props) => {
    const { open, onClose, close, getDetails } = props;
    const [showVideoDetails, setShowVideoDetails] = useState({});
    useEffect(() => {
        if (open) {
            setShowVideoDetails(getDetails);
        }
    }, [open]);
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "40px",
                        padding: '50px 50px 0px 50px',
                        height: "575px",
                        overflow: "scroll",
                        "::-webkit-scrollbar" : {width:"0px",height:'0px'}
                    },
                    "& .MuiDialogContent-root": {
                        overflowY: "initial",
                    },
                }}
            >
                <Box
                    sx={{ width: "100%", justifyContent: "center", display: "flex", justifyContent: 'space-between', marginBottom: '30px' }}
                >
                    <IconButton onClick={close}>
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ fontSize: "32px", fontWeight: "500" }}>
                        View Details
                    </Typography>
                    <Typography></Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box sx={{
                            width: '100%',
                            height: "250px",
                        }}>
                            <img src={`${Constant.BASE_URL}${showVideoDetails?.thumb}`} alt="thumb" style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '10px'
                            }} />
                        </Box>
                    </Grid>
                    {showVideoDetails?.schedule_date !== <Grid item xs={12}>
                        <Typography
                            sx={{
                                color: "black",
                                fontWeight: "500",
                                textAlign: "left",
                                fontSize: { xs: "16px", sm: "16px" },
                                mb:'5px'
                            }}
                        >
                            Schedule
                        </Typography>
                        <Typography sx={{
                            borderRadius: "8px",
                            background: "white",
                            fontSize: "18px",
                            border: '1px solid #0000003D',
                            padding: '12px 20px 12px 20px',
                            background: '#F0F0F0'
                        }}>fdfdf</Typography>
                    </Grid>}
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                color: "black",
                                fontWeight: "500",
                                textAlign: "left",
                                fontSize: { xs: "16px", sm: "16px" },
                                mb:'5px'
                            }}
                        >
                            Category Name
                        </Typography>
                        <Typography sx={{
                            borderRadius: "8px",
                            background: "white",
                            fontSize: "18px",
                            border: '1px solid #0000003D',
                            padding: '12px 20px 12px 20px',
                            background: '#F0F0F0'
                        }}>{showVideoDetails?.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                color: "black",
                                fontWeight: "500",
                                textAlign: "left",
                                fontSize: { xs: "16px", sm: "16px" },
                                mb:'5px'
                            }}
                        >
                            Title
                        </Typography>
                        <Typography sx={{
                            borderRadius: "8px",
                            background: "white",
                            fontSize: "18px",
                            border: '1px solid #0000003D',
                            padding: '12px 20px 12px 20px',
                            background: '#F0F0F0'
                        }}>{showVideoDetails?.title}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                color: "black",
                                fontWeight: "500",
                                textAlign: "left",
                                fontSize: { xs: "16px", sm: "16px" },
                                mb:'5px'
                            }}
                        >
                            Link
                        </Typography>
                        <Typography sx={{
                            borderRadius: "8px",
                            background: "white",
                            fontSize: "18px",
                            border: '1px solid #0000003D',
                            padding: '12px 20px 12px 20px',
                            background: '#F0F0F0'
                        }}>{showVideoDetails?.link}</Typography>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    );
};

export default ViewVideoModel;
