import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';
import "./index.css";
import "react-phone-input-2/lib/style.css";
import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
      <Suspense fallback>
      <BrowserRouter>
         <App />
      </BrowserRouter>
      </Suspense>
    </React.StrictMode>
);
reportWebVitals();
