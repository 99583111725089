export const ApiList = {
    //AUTH
    LOGIN : "/auth/admin-login",
    GET_BY_ID:"/user/get-data",
    FORGOT_PASSWORD: "/auth/forgot-password",
    DASHBOARD:"/admin/dashboard-data",
    GET_GALLERY : "/gallery/get",
    POST_GALLERY:"/gallery/add",
    DELETE_GALLERY:"/gallery/edit",
    GET_CATEGORY : "/category/get",
    POST_CATEGORY: "/category/add",
    GET_CATEGORY:"/category/get",
    GET_VIDEO:"/video/get",
    ADD_VIDEO:"/video/add",
    DELETE_VIDEO:"/video/edit",
    EDIT_VIDEO:'/video/edit',
    UPDATE_TESTIMONIAL_STATUS:'/video/edit',
    GET_STREAM:"/stream/get",
    ADD_UPDATE_STREAM:"/stream/edit",
    ADD_RESOURCES: "/resourses/add",
    GET_RESOURCES: "/resourses/get",
    DELETE_RESOURCES:"/resourses/edit",
    GET_USERS: "/user/get-list",
    DELETE_BLOCK_USER:"/user/update",
    GET_AFFIRMATION:"/affirmation/get",
    ADD_AFFIRMATION:"/affirmation/add",
    EDIT_DELETE_AFFIRMNATION:"/affirmation/edit ",
    GET_HEALTH:"/health-tips/get",
    ADD_HEALTH:"/health-tips/add",
    EDIT_DELETE_HEALTH_TIPS:"/health-tips/edit",
    GET_REGISTER:"/registration/get",
    CONTENT_MANAGEMENT_GET:"/content/get",
    CONTENT_MANAGEMENT_EDIT:"/content/edit",
    ADD_EVENT:"/event/add",
    GET_EVENT:"/event/get",
    DELETE_EVENT:"/event/edit",
    EDIT_EVENT:"/event/edit",
    ADD_FAQ:"/faq/add",
    GET_FAQ:"/faq/get",
    EDIT_FAQ:"/faq/edit",
    GET_CONTACT:"/contact/get",
    ADD_CONTACT:"/contact/add",
    EDIT_CONTACT:"/contact/edit",
    UPDATE_EVENT_STATUS:'/event/edit',
}