import { Box, Button, Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import dummyUser from "../../../Assets/Images/avtar.webp";
import EditIcon from "../../../Assets/Images/edit.png";
import { ApiList } from "../../../Config/ApiList";
import AxiosInstance from "../../../Config/AxiosInstance";
import deleteIcon from "../../../Assets/Images/delete.png";
import viewIcon from "../../../Assets/Images/view.png";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Constant from "../../../utills/Constant";
import AddVideoModel from "./AddVideoModel";
import ViewVideoModel from './ViewVideoModel';

const VideoManagement = () => {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [openVideoModel, setOpenVideoModel] = useState(false);
  const [viewVideoModel, setViewVideoModel] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [singleDetails, setSingleDetails] = useState({});
  const [typeCheck, setTypeCheck] = useState(null);

  const columns = [
    {
      field: "rowid", headerName: "Sr.No.", width: 100, renderCell: (params) => (
        params?.row?.rowid ? params?.row?.rowid : "--"
      )
    },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      width: 100,
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <img
            src={
              row?.thumb
                ? `${Constant.BASE_URL}${row?.thumb}`
                : dummyUser
            }
            alt="thumbnail"
            style={{ width: "40px", height: "40px", borderRadius: "5px" }}
          />
        </Box>
      ),
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        params?.row?.title ? params?.row?.title : "--"
      )
    },
    {
      field: "name",
      headerName: "Category",
      minWidth: 200,
      renderCell: (params) => (
        params?.row?.name ? params?.row?.name : "--"
      )
    },
    {
      field: "link",
      headerName: "Link",
      minWidth: 500,
      renderCell: (params) => (
        params?.row?.link ? params?.row?.link : "--"
      )
    },
    {
      field: "is_testimonial_month",
      headerName: "Testimonies of the Month",
      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={params?.row?.is_testimonial_month == 1 ? true : false}
                  onChange={(e) => handleUpdateTestimonialStatus(params?.row?.id, e.target.checked)}
                />
              }
            />
          </Box>
        )
      }
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <img src={EditIcon}
            alt="editIcon"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={(e) => handleEditVideo(params?.row)}
          />
          <img
            src={viewIcon}
            alt="view"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => handleViewVideoModel(params?.row)}
          />
          <img
            src={deleteIcon}
            alt="delete"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => handleDeleteVideo(params?.row?.id)}
          />
        </Box>
      ),
    },
  ];

  useEffect(() => {
    getVideoList();
  }, [page]);

  const getVideoList = async () => {
    setIsLoading(true);
    try {
      const params = {
        count: page * pageSize,
        is_admin: 1,
      };
      const response = await AxiosInstance.get(ApiList.GET_VIDEO, {
        params: params
      });
      if (response && response?.data?.s) {
        const Rowdata = response.data.r.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setVideoList(Rowdata);
        setIsLoading(false);
        setCount(response?.data?.c);
      } else {
        setVideoList([]);
      }
    } catch (error) {
      console.log("error");
      setIsLoading(false);
    }
  };

  const handleViewVideoModel = (data) => {
    setViewVideoModel(true);
    setSingleDetails(data);
  };

  const handleDeleteVideo = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Video",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const params = {
          id: id,
          status: "0",
        };
        try {
          const response = await AxiosInstance.post(
            ApiList.DELETE_VIDEO,
            params
          );
          if (response?.data?.s === 1) {
            getVideoList(); // Refresh the video list after deletion
            toast.success("Video Deleted Sucessfully !.");
          }
        } catch (error) {
          toast.error("Failed to delete video. Please try again.");
        }
      }
    });
  };

  const handleEditVideo = (data) => {
    setOpenVideoModel(true);
    setTypeCheck('Edit');
    setSingleDetails(data);
  };

  const handleAddVideo = () => {
    setOpenVideoModel(true);
    setTypeCheck(null);
    setSingleDetails({});
  };

  const handleUpdateTestimonialStatus = async (id, checked) => {
    try {
      const status = checked ? 1 : 0;
      const params = {
        id: id,
        is_testimonial_month: status.toString()
      };
      const response = await AxiosInstance.post(ApiList.UPDATE_TESTIMONIAL_STATUS, params);
      if (response && response?.data?.s === 1) {
        toast.success('Testimonial Of Month Status Updated Successfully!');
        getVideoList(); // Refresh the video list after status update
      } else {
        toast.error('Failed to update Testimonial Of Month Status.');
      }
    } catch (error) {
      toast.error('An error occurred while updating the status. Please try again.');
      console.error("Error updating testimonial status:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Box sx={{ width: "100%", padding: '15px' }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: { xs: "20px", md: "28px" }, fontWeight: "500", paddingBottom: '10px' }}>
                Video Management
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}>
                <Button
                  sx={{
                    background: "#000E72",
                    color: "white",
                    "&:hover": { background: "#000E72", color: "white" },
                    textTransform: "none",
                  }}
                  onClick={handleAddVideo}
                >
                  + Add Video
                </Button>
              </Box>
            </Grid>
          </Grid>
        <Box sx={{ padding: "24px 0px 24px 0px", borderTop: '1px solid #0000003D' }}>
          <TableContainer
            rows={videoList}
            columns={columns}
            pageSize={pageSize}
            loading={isLoading}
            page={page}
            handlePageChange={handlePageChange}
            totalCount={count}
          />
        </Box>
      </Box>
      <AddVideoModel
        open={openVideoModel}
        close={() => {
          setOpenVideoModel(false);
          setTypeCheck(null);
          setSingleDetails({});
        }}
        onClose={() => {
          setOpenVideoModel(false);
          setTypeCheck(null);
          setSingleDetails({});
        }}
        typeCheck={typeCheck}
        editDetails={singleDetails}
        onSubmit={() => {
          setOpenVideoModel(false);
          getVideoList();
        }}
      />
      <ViewVideoModel
        getDetails={singleDetails}
        open={viewVideoModel}
        close={() => setViewVideoModel(false)}
        onClose={() => setViewVideoModel(false)} />
    </>
  );
};

export default VideoManagement;
