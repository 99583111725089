import React, { useState } from "react";
import Button from "@mui/material/Button";
import { Box, IconButton } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const CustomPagination = ({ page, pageSize, handlePageChange, totalCount }) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px",
        gap: "10px",
      }}
    >
      <Button
        sx={{
          bgcolor: page === 0 ?  "white" :   "#000E72",
          color: page === 0 ?  "#C3D5E5" :   "white",
          border: page === 0 ?  "1px solid #C3D5E5" :   "1px solid #000E72",
          fontSize: "16px",
          width:"100px",
          px: 2,
          py:0,
          m: 0,
          "&:hover" : {bgcolor: "#000E72",
            color: "white",}
             
        }}
        disabled={page === 0}
        onClick={() => handlePageChange(page - 1)}
      >
        Previous
      </Button>
      <Button
        sx={{
          bgcolor: page + 1 >= totalPages ?  "white" :   "#000E72",
          color: page + 1 >= totalPages ?  "#C3D5E5" :   "white",
          border: page + 1 >= totalPages ?  "1px solid #C3D5E5" :   "1px solid #000E72",
          fontSize: "16px",
          width:"100px",
          px: 2,
          py:0,
          m: 0,
          "&:hover" : {bgcolor: "#000E72",
            color: "white",}
             
        }}
        disabled={page + 1 >= totalPages}
        onClick={() => handlePageChange(page + 1)}
      >
        Next
      </Button>
    </Box>
  );
};

export default CustomPagination;
