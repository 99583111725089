import React from "react";
import { useDropzone } from "react-dropzone";
import { Box, IconButton, Typography } from "@mui/material";
import cloudIcon from "../../Assets/Images/uploadIcon.png";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

const CustomThumbUpload = ({ onDrop, accept, selectedFile, onRemove, typeCheck, isLoading }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toast.error("Invalid file type for thumb!");
      }
    },
  });

  return (
    <Box
      sx={{
        textAlign: "center",
        p: isLoading || selectedFile ? "0px" : 4,
        borderRadius: 4,
        backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
        border: "1px solid black",
        position: "relative",
        height: "250px",
      }}
    >
      {isLoading ? (
        <Skeleton height={240}/>
      ) : !selectedFile ? (
        <div
          {...getRootProps()}
          style={{
            cursor: "pointer",
            padding: "20px",
            borderRadius: "8px",
            backgroundColor: isDragActive ? "#f0f0f0" : "#fafafa",
            height: "100%",
          }}
        >
          <input {...getInputProps()} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={cloudIcon} alt="" />
            <Typography
              variant="body1"
              color="black"
              sx={{
                mt: 1,
                textAlign: "center",
                px: { xs: 2, sm: 3, md: 3, lg: 10, xl: 10 },
                fontWeight: "500",
              }}
            >
              <span style={{ color: "#000E72" }}>Click here</span> to upload
              thumb or drag and drop
            </Typography>
            <Typography
              variant="body1"
              color="black"
              sx={{
                mt: 1,
                textAlign: "center",
                px: { xs: 2, sm: 3, md: 3, lg: 10, xl: 10 },
                fontWeight: "500",
              }}
            >
              <span style={{ color: "#ddd" }}>(JPG,JPEG,PNG,WEBP)</span>
            </Typography>
          </Box>
        </div>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              width: "80%",
              height: "95%",
            }}
          >
            <img
              src={selectedFile}
              alt="thumb"
              style={{
                width: "100%",
                borderRadius: "10px",
                height: "100%",
              }}
            />
          </Box>

          {typeCheck === "View" ? null : (
            <IconButton
              onClick={onRemove}
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "red",
                color: "white",
                "&:hover": { background: "red", color: "white" },
                textTransform: "none",
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CustomThumbUpload;
