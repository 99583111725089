import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import { toast } from "react-toastify";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
const AddLiveStream = (props) => {
  const { open, onClose, close, onSubmit } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [streamList, setStreamList] = useState({});
  useEffect(() => {
    getStreamList();
  }, []);

  const getStreamList = async () => {
    try {
      const response1 = await AxiosInstance.get(ApiList.GET_STREAM, {
        params: {
          type: 1,
        },
      });
      const response2 = await AxiosInstance.get(ApiList.GET_STREAM, {
        params: {
          type: 2,
        },
      });
      if (
        response1.data &&
        response1.data.s === 1 &&
        response2.data &&
        response2.data.s === 1
      ) {
        const streamList1 = response1.data.r;
        const streamList2 = response2.data.r;
        setStreamList({
          type1: streamList1.link || "",
          type2: streamList2.link || "",
        });
      } else {
        setStreamList({ type1: "", type2: "" });
        console.error(
          "Failed to fetch stream lists:",
          response1.data ? response1.data.m : "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching stream lists:", error);
      setStreamList({ type1: "", type2: "" });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      streamLink: streamList.type1 || "",
      videoLink: streamList.type2 || "",
    },
    validationSchema: Yup.lazy((values) => {
      return Yup.object().shape({
        streamLink:
          values.streamLink &&
          Yup.string().matches(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
            "Please provide a valid stream link"
          ),
        videoLink:
          values.videoLink &&
          Yup.string().matches(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
            "Please provide a valid video link"
          ),
      });
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        return;
      } else {
        try {
          const formData1 = new FormData();
          formData1.append("type", 1);
          formData1.append("live_stream", values.streamLink);

          const formData2 = new FormData();
          formData2.append("type", 2);
          formData2.append("prayer", values.videoLink);
          if (values.streamLink) {
            await AxiosInstance.post(ApiList.ADD_UPDATE_STREAM, formData1);
          }
          if (values.videoLink) {
            await AxiosInstance.post(ApiList.ADD_UPDATE_STREAM, formData2);
          }
          toast.success("Links submitted successfully!");
          setIsLoading(false);
          resetForm();
          getStreamList();
          if (onSubmit) onSubmit();
          onClose();
        } catch (error) {
          console.error("Error submitting form", error);
          setIsLoading(false);
        }
      }
    },
  });
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "40px",
            p: 2,
          },
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Box
            sx={{
              display: "flex",
              pt: 2,
              pl: 2,
              width: "100%",
              alignItems: "center",
              borderRadius: "40px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <IconButton onClick={close}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{ width: "100%", justifyContent: "center", display: "flex" }}
          >
            <Typography sx={{ fontSize: "32px", fontWeight: "500" }}>
              Live Stream
            </Typography>
          </Box>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CustomTextField
                  label="Add Live Stream Link"
                  type="text"
                  placeholder="Link"
                  name="streamLink"
                  value={formik.values.streamLink}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}
                  errors={formik.touched.streamLink && formik.errors.streamLink}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Add Prayer Video Link"
                  type="text"
                  placeholder="Link"
                  name="videoLink"
                  value={formik.values.videoLink}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (value.trimStart() !== value) {
                      e.target.value = value.trimStart();
                    }
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}
                  errors={formik.touched.videoLink && formik.errors.videoLink}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                    },
                  }}
                />
              </Grid>
            </Grid>
            {/* <RadioGroup
              value={formik.values.scheduleSelected}
              onChange={formik.handleChange}
              name="scheduleSelected"
            >
              <Box sx={{ display: "flex", width: "100%" }}>
                <FormControlLabel
                  value={"1"}
                  control={<Radio />}
                  label="Publish Now"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio />}
                  label="Schedule Later"
                />
              </Box>
            </RadioGroup> */}
          </DialogContent>
          <Box
            sx={{
              py: 3,
              px: 4,
              display: "flex",
              width: "100%",
              justifyContent: "end",
            }}
          >
            <CustomLoaderButton
              btnTitle={"Submit"}
              sx={{ width: "150px" }}
              type="submit"
              loading={isLoading}
            />
          </Box>
        </form>
      </Dialog>
    </>
  );
};

export default AddLiveStream;
