import { Box, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import * as Yup from "yup";
import { useFormik } from "formik";
import EditIcon from "../../../Assets/Images/edit.png";
import Skeleton from "react-loading-skeleton";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import { toast } from "react-toastify";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";

const PrivecyPolicy = () => {
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const getTermsAndCondition = async () => {
    try {
      const response = await AxiosInstance.get(ApiList.CONTENT_MANAGEMENT_GET, {
        params: {
          type: 2,
        },
      });
      if (response && response?.data?.s === 1) {
        formik.setFieldValue("privecy_policy", response?.data?.r?.text);
        setIsLoading(false);
        setEditMode(true);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to fetch terms and conditions. Please try again.");
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getTermsAndCondition();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      privecy_policy: "",
    },
    validationSchema: Yup.object({
      privecy_policy: Yup.string().required("Terms condition is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const formData = new FormData();
        formData.append("text", values.privecy_policy);
        formData.append("type", 2)
        const res = await AxiosInstance.post(
          ApiList.CONTENT_MANAGEMENT_EDIT,
          formData
        );
        if (res && res.data?.s) {
          toast.success(res.data.m);
          resetForm();
          setIsLoading(false);
          getTermsAndCondition()
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
  });
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
          style={{
            position: "relative",
            backgroundColor: "#fff",
            boxShadow:'0px 0px 10px rgba(0,0,0,0.1)',
            padding:'40px 10px 10px 10px'
          }}
        >
          {isEditMode && (
            <IconButton
              type="button"
              sx={{
                position: "absolute",
                top: "5px",
                right: "10px",
                width: "30px",
                height: "30px",
                backgroundColor: "#ffffff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#ffffff",
                },
                cursor: "pointer",
              }}
              onClick={() => setEditMode(false)}
            >
              <img src={EditIcon} alt="editIcon" />
            </IconButton>
          )}
          {isLoading ? (
            <Skeleton width={"100%"} height={"200px"} />
          ) : (
            <ReactQuill
              placeholder="Description"
              value={formik.values.privecy_policy}
              ref={ref}
              readOnly={isEditMode}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                  ["bold", "italic", "underline"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  [
                    { align: "right" },
                    { align: "center" },
                    { align: null },
                    { align: "justify" },
                  ],
                ],
              }}
              onChange={(content, delta, source, editor) => {
                content = content.replace(/(^<[^>]*>)([\s]*)/gm, "$1");
                formik.setFieldValue("privecy_policy", content);
              }}
              className="termsCondtionContent"
            />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginTop: "24px",
            }}
          >
            <CustomLoaderButton
              type="submit"
              fullWidth
              disabled={
                !formik?.values?.privecy_policy
                  ?.replace(/<[^>]+>/g, "")
                  .trim() || isEditMode
              }
              btnTitle="Save"
              loading={isLoading}
              sx={{
                width: "120px",
                height: "48px",
                
              }}
            />
          </Box>
        </form>
      </Box>
    </>
  );
};

export default PrivecyPolicy;
