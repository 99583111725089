import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";

const PiChartOne = () => {
  const [dashboardData, setDashboardData] = useState({ videos: 0, images: 0, affirmations: 0 });

  const getDashboaredData = async () => {
    try {
      const response = await AxiosInstance.get(ApiList.DASHBOARD);
      if (response && response?.data?.s === 1) {
        setDashboardData(response?.data?.r);
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  useEffect(() => {
    getDashboaredData();
  }, []);

  const data = {
    labels: [
      `Videos ${dashboardData.videos || 0}`, 
      `Images ${dashboardData.images || 0}`, 
      `Affirmations and Daily Health Tips ${dashboardData.affirmations || 0}`
    ],
    datasets: [
      {
        label: "Total Posts",
        data: [dashboardData.videos, dashboardData.images, dashboardData.affirmations],
        backgroundColor: ["#D3D3D3", "#6A5ACD", "#00008B"],
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          font: {
            size: 18,
          },
        },
      },
      title: {
        display: true,
        text: "Total Posts",
        align: "start",
        color: "black",
        font: {
          size: 26,
          weight: 600,
        },
        padding: {
          top: 10,
          bottom: 30,
          start: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || "";
            if (label) {
              label += ": ";
            }
            if (context.raw !== null) {
              label += new Intl.NumberFormat().format(context.raw);
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%', display: "flex", justifyContent: 'center' }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PiChartOne;
