const PathList = {
    Login : '/',
    Dashboard: '/admin/dashboard',
    UserManagement : '/admin/user-management',
    RegisterUser : '/admin/register-management',
    VideoManagement : '/admin/video-management',
    Gallery : '/admin/gallery-management',
    ResourcesManagement : '/admin/resouces-management',
    Affirmative : '/admin/affirmative-tips-management',
    DAILYHEALTHTIP:"/admin/daily-health-tips-management",
    SCHEDULEEVENTMANAGMENT:"/admin/schedule-event-management",
    ContentManagement : '/admin/content-management',
}

export default PathList