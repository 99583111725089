import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import { toast } from "react-toastify";
import deleteIcon from "../../../Assets/Images/delete.png";
import EditIcon from "../../../Assets/Images/edit.png";
import Swal from "sweetalert2";
import TableContainer from "../../../Components/TableContainer/TableContainer";
import PhoneInput from "react-phone-input-2";

const ContactUs = () => {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [typeCheck, setTypeCheck] = useState(null);
  const [editContactId, setEditContactId] = useState(null);

  useEffect(() => {
    getContactDetails();
  }, [page, count]);

  const getContactDetails = async () => {
    setIsLoading(true);
    try {
      const res = await AxiosInstance.get(ApiList.GET_CONTACT, {
        params: { count: count },
      });
      if (res?.data?.s === 1) {
        const Rowdata = res?.data?.r?.map((data, i) => ({
          rowid: i + 1 + page * pageSize,
          ...data,
        }));
        setContactList(Rowdata);
        setCount(res?.data?.c);
      } else {
        setContactList([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setCount(newPage * pageSize);
  };

  const handleEditContact = (data) => {
    const editData = contactList.find((ele) => ele.id === data.id);
    if (editData) {
      setTypeCheck("Edit");
      setEditContactId(data.id);
      formik.setValues({
        email: editData.email || "",
        phon_cc: editData.cc || "",
        phno: editData.number || "",
      });
    }
  };

  const handleDeleteContact = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this contact",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#46adf2",
      cancelButtonColor: "#5b5d5d",
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "Cancel",
    });
    if (result.isConfirmed) {
      try {
        const response = await AxiosInstance.post(ApiList.EDIT_CONTACT, {
          id,
          status: "0",
        });
        if (response?.data?.s === 1) {
          getContactDetails();
          toast.success("Deleted Successfully!");
        }
      } catch (error) {
        toast.error("Failed to delete.");
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      phno: "",
      phon_cc: ""
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Email address is required!")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Please provide valid email address"
        ),
      phno: Yup.string().required("Mobile number is required!"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      const apiUrl =
        typeCheck === "Edit" ? ApiList.EDIT_CONTACT : ApiList.ADD_CONTACT;
      const formData = new FormData();
      if (typeCheck === "Edit") formData.append("id", editContactId);
      formData.append("email", values.email);
      formData.append("number", values.phno);
      formData.append('cc', values.phon_cc);
      try {
        const response = await AxiosInstance.post(apiUrl, formData);
        if (response?.data?.s === 1) {
          toast.success('Contact Us Added Successfully');
          resetForm();
          getContactDetails();
          setTypeCheck(null);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const columns = [
    { field: "rowid", headerName: "Sr.No.", width: 100 },
    { field: "email", headerName: "Email", flex: 1, renderCell: (params) => params?.row?.email || "-" },
    { field: "number", headerName: "Mobile Number", flex: 1,renderCell: (params) => `+${params?.row?.cc} ${params?.row?.number}` || "-" },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "15px",
          }}
        >
          <img
            src={EditIcon}
            alt="editIcon"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => handleEditContact(params?.row)}
          />
          <img
            src={deleteIcon}
            alt="delete"
            style={{ width: "24px", height: "24px", borderRadius: "5px" }}
            onClick={() => handleDeleteContact(params?.row?.id)}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ padding: "24px 0px 0px 0px" }}>
        <TableContainer
          rows={contactList}
          loading={isLoading}
          columns={columns}
          pageSize={pageSize}
          page={page}
          totalCount={count}
          handlePageChange={handlePageChange}
          sx={{ height: "auto" }}
        />
      </Box>
      <Box sx={{ width: { xs: "100%", md: "40%" } }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <CustomTextField
              label="Email"
              type="text"
              placeholder="Email Address"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errors={formik.touched.email && formik.errors.email}
              onKeyUp={formik.handleChange}
              sx={{
                "& .MuiInputBase-root": {
                  background: "#F0F0F0",
                  borderRadius: "10px",
                },
              }}
              disabled={typeCheck === "Edit"}
              isLoading={isLoading}
            />
            <Typography
              sx={{ color: "black", fontWeight: "500", fontSize: "18px" }}
            >
              Contact Number
            </Typography>
            <PhoneInput
              country={"us"}
              name="phno"
              placeholder="Enter Mobile Number"
              value={formik.values.phon_cc + formik.values.phno}
              onChange={(value, country) => {
                const countryCodeLength = country.dialCode.length;
                const phon_cc = value.slice(0, countryCodeLength);
                const phno = value.slice(countryCodeLength);
                formik.setFieldValue("phno", phno);
                formik.setFieldValue("phon_cc", phon_cc);
              }}
              onBlur={formik.handleBlur}
              onKeyUp={formik.handleChange}
              inputClass="inputClassCountry"
              style={{ border: formik.errors.phno ? "1px solid red" : "none", borderRadius: "8px" }}
              isLoading={isLoading}
            />
            {formik.touched.phno && formik.errors.phno ? (
              <Typography
                sx={{
                  color: "red",
                  fontWeight: "400",
                  fontSize: { xs: "14px", sm: "14px" },
                  marginTop: "5px",
                }}
              >
                {formik.errors.phno}
              </Typography>
            ) : null}
            <Box
              sx={{
                py: 3,
                px: 0,
                display: "flex",
                width: "100%",
                justifyContent: "end",
              }}
            >
              <CustomLoaderButton
                btnTitle={typeCheck === "Edit" ? "Update" : "Add"}
                sx={{ width: "150px" }}
                type={"submit"}
                loading={isLoading}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default ContactUs;
